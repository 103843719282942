<template>
    <footer id="footer" class="footer-container">
        <MailSignup />
        <FooterContent />
        <a href="#" v-show="showScrollUp" @click.prevent="scrollup" class="up scrollup" aria-label="Scroll to Top">up</a>
    </footer>
</template>

<script>
import MailSignup from './Footer/MailSignup'
import FooterContent from "./Footer/FooterContent"

export default {
    name: "Footer",
    components: {
        FooterContent,
        MailSignup
    },
    data(){
        return {
            showScrollUp: false,
        }
    },
    methods:{
        handleScroll(){
            this.showScrollUp = window.scrollY > 120 ? true : false;
        },
        scrollup(){
            window.scrollTo({top: 0, behavior: 'smooth'});
        },
    },
    created: function(){
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed(){
      window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>

<style lang="scss" scoped>

</style>
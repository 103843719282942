<template>
<div id="mail-signup" class="mail-container">
    <div id="mc_embed_signup">
        <h2>Newsletter signup</h2>
        <form
            action="https://meredithschlosser.us7.list-manage.com/subscribe/post?u=a88ddc5ec59588a175d73b9c1&amp;id=3eecbe0c77"
            method="post" id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank"
            novalidate>
            <div id="mc_embed_signup_scroll">
                <input aria-label="Enter your email address" type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email@address.com" required>
                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true">
                    <input type="text" name="b_a88ddc5ec59588a175d73b9c1_3eecbe0c77" tabindex="-1" value="">
                </div>
                <input aria-label="Submit to subscribe our newsletter" type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" v-bind:disabled="!checked">
            </div>
        </form>
        <div class="user-consent">
            <input aria-label="Check to authorize receiving promotional emails" type="checkbox" id="consent" name="consent" v-model="checked">
            <label for="consent">I would like to receive promotional emails.</label>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "MailSignup",
    data(){
        return {
            checked: false,
        }
    },
}
</script>
<style lang="scss" scoped>
@media (max-width: $mobile) { 
    #mce-EMAIL{
        margin-left: 10px;
        max-width: 300px !important;
        display: block;
    }
    #mc-embedded-subscribe{
        right: 20px !important;
    }
    .user-consent{
        margin-left: 10px;
    }
}
</style>
